import React from 'react'
import { withRouter } from 'react-router-dom';

class ProjectCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = ({

    })
  }

  handleClick = () => {
    this.props.history.push(this.props.github)
  }
  render() {
    return (
      < div id={this.props.cssId} className='project-cards' >
        <div className='project-card-overlays'>
          <p className='project-card-titles'>{this.props.title}</p>
          <p className='project-card-descrips'>{this.props.descrip}</p>
          <p className='project-card-tools'>{this.props.tools}</p>
          <p className='github-project-link' onClick={this.handleClick}>GitHub</p>
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectCard)