import React from 'react'



export default (props) => {
  return (
    <>
      <div id='home'>
      </div>
      <div id='hero-section-container'>
        <p>Hi, I am a Full Stack Web Developer and Software Engineer with 10+ years of experience working with tech products for both large and small organizations.</p>
      </div>
    </>
  );
}